import { initializeApp } from 'firebase/app';
import { getDatabase} from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDPIGz1kqJvhj2QtMwqqre4hTbYXWOaT2M",
  authDomain: "sidehustle-756d8.firebaseapp.com",
  projectId: "sidehustle-756d8",
  storageBucket: "sidehustle-756d8.appspot.com",
  messagingSenderId: "683735258677",
  appId: "1:683735258677:web:b3264740a2c0886632437c",
  measurementId: "G-BMPRDET999"
};

  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);

  export default db;
