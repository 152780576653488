import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "./navbar2.css";
import { Link } from "react-router-dom";
import logo_light from '../images/logo_light.png'
import logo_dark from '../images/logo_dark.png'

const pages = ["Home", "About Us", "Journey", "Get in touch"];


function Navbar2() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <Box className={scrolled ? "scrolled2" : "not-scrolled2"} sx={{paddingY:{xs:"0.2rem",md:"0.8rem"}}}></Box>
      <Box
        className={scrolled ? "scrolled2" : "not-scrolled2"}
        sx={{ position: "sticky", paddingX:{sm:"2rem",md:"3rem"} }}
      >
        <Container>
          <Toolbar disableGutters >
            <Box component="img" src={scrolled ?logo_dark:logo_light} sx={{ display: { xs: 'none', md: 'flex' }, width:"7rem",mr: 1 }} />
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color={scrolled ? "secondary" : "primary"}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/';
                    handleCloseNavMenu();
                    }}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
                <MenuItem>
                <Link style={{textDecoration:"none", color:"black"}} to={"https://drive.google.com/file/d/1XOjbTeCrFCvIFaYzioHop45CO1XXVoXL/view"} onClick={(e)=>{handleCloseNavMenu()}} target="_blank" rel="noreferrer" >
                <Typography textAlign="center" >Brochure</Typography>
              </Link>
              </MenuItem>
              </Menu>
            </Box>
            <Box component="img" src={scrolled ?logo_dark:logo_light}
            sx={{ display: { xs: "flex", md: "none" },  width: "7rem" }}
            />
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} >
                    
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/';
                    }}
                  color={scrolled ? "secondary" : "primary"}
                  sx={{  display: "block", fontSize: "0.95rem" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" }, gap:"1rem" }}>
              {/* <Link to={"/form"} target="_blank" rel="noreferrer">
                <Button
                  variant="contained"
                  color={scrolled ? "secondary" : "primary"}
                  sx={{ py: 1 }}
                >
                  Apply Now
                </Button>
              </Link> */}
              <Link to={"https://drive.google.com/file/d/1XOjbTeCrFCvIFaYzioHop45CO1XXVoXL/view"} target="_blank" rel="noreferrer">
                <Button
                  variant="contained"
                  color={scrolled ? "secondary" : "primary"}
                  sx={{ py: 1 }}
                >
                  Brochure
                </Button>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </>
  );
}
export default Navbar2;
