import React, { useState } from "react";
import "./form.css";
import db from "./Firebase";
import { useNavigate } from "react-router-dom";
import { ref, set } from "firebase/database";
import {
  Container,
  Paper,
  Box,
  FormControl,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  Typography,
  Input,
  Divider,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Submit Button
export default function Form() {
  
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    set(ref(db, "users/" + contact), {
      Name: name,
      Email: email,
      Contact: contact,
      Gender: gender,
      Know: know,
      DOB: dob,
      Location: location,
      College: college,
      YOD: yod,
      Degree: degree,
      LinkedIn: linkedin,
      Level: level,
      CofounderBool: cofounderbool,
      Cofounders: cofounder,
      Vision: vison,
      Challenging_situation: situation,
      Money: money,
      Projects: project,
      Song: song,
      Q1: que1,
      Q2: que2,
      Q3: que3,
      Q4: que4,
      Q5: que5,
      Q6: que6,
      Q7: que7,
      Q8: que8,
      Idea: idea,
      StartupIdea: startupIdea,
      Problem: problem,
      Solution: solution,
      Inspiration: inspiration,
      Competitors: competitors,
      Challenges: challenges,
    });

    alert("Form data submitted successfully!");
    setTimeout(function () {
      navigate("/");
    }, 1000);
  };

  //Next Buttons
  const [x, setX] = useState(1);
  const handleNextClick = () => {
    const currentForm = document.getElementById(`Form${x}`);
    if (currentForm) {
      currentForm.style.display = "none";
    }
    setX((prevX) => prevX + 1);
    const nextForm = document.getElementById(`Form${x + 1}`);
    if (nextForm) {
      nextForm.style.display = "block";
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  //Previous Button
  const handlePreviousClick = () => {
    if (x > 1) {
      const currentForm = document.getElementById(`Form${x}`);
      if (currentForm) {
        currentForm.style.display = "none";
      }

      setX((prevX) => prevX - 1);

      const previousForm = document.getElementById(`Form${x - 1}`);
      if (previousForm) {
        previousForm.style.display = "block";
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [know, setKnow] = useState("");
  const [dob, setDob] = useState("");
  const [location, setLocation] = useState("");
  const [college, setCollege] = useState("");
  const [yod, setYod] = useState("");
  const [degree, setDegree] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [level, setLevel] = useState("");
  const [cofounderbool, setCofounderbool] = useState("no");
  const [cofounder, setCofounder] = useState("");
  const [vison, setVision] = useState("");
  const [situation, setSituation] = useState("");
  const [money, setMoney] = useState("");
  const [project, setProject] = useState("");
  const [song, setSong] = useState("");
  const [que1, setQue1] = useState("");
  const [que2, setQue2] = useState("");
  const [que3, setQue3] = useState("");
  const [que4, setQue4] = useState("");
  const [que5, setQue5] = useState("");
  const [que6, setQue6] = useState("");
  const [que7, setQue7] = useState("");
  const [que8, setQue8] = useState("");
  const [idea, setIdea] = useState("yes");
  const [startupIdea, setStartupIdea] = useState("-");
  const [problem, setProblem] = useState("-");
  const [solution, setSolution] = useState("-");
  const [inspiration, setInspiration] = useState("-");
  const [competitors, setCompetitors] = useState("-");
  const [challenges, setChallenges] = useState("-");

  return (
    <div className="anime">
    <Box
      sx={{
        // bgcolor: "secondary.main",
        padding: { xs: "3rem 0 16rem", md: "5rem 6rem" },
      }}
    >
      <Container>
      <div className="progress">
        <div className="progress-bar "><div className={`bar ${(x===1)? 'active-now' : ''} ${((x===2)|(x===3)|(x===4)|(x===5))? 'active' : ''}`}> <Box paddingTop={"1rem"} sx={{ fontSize:{xs:"0.65rem" , sm:"1rem"}}}>Section 1</Box></div></div>
        <div className="progress-bar "><div className={`bar ${(x===2)? 'active-now' : ''} ${((x===3)|(x===4)|(x===5))? 'active' : ''}`}><Box paddingTop={"1rem"} sx={{ fontSize:{xs:"0.65rem" , sm:"1rem"}}}>Section 2</Box></div></div>
        <div className="progress-bar "><div className={`bar ${(x===3)? 'active-now' : ''} ${((x===4)|(x===5))? 'active' : ''}`}><Box paddingTop={"1rem"} sx={{ fontSize:{xs:"0.65rem" , sm:"1rem"}}}>Section 3</Box></div></div>
        <div className="progress-bar "><div className={`bar ${(x===4)? 'active-now' : ''}${((x===5))? 'active' : ''}`}><Box paddingTop={"1rem"} sx={{ fontSize:{xs:"0.65rem" , sm:"1rem"}}}>Section 4</Box></div></div>
        <div className="progress-bar "><div className={`bar ${(x===5)? 'active-now' : ''}`}><Box paddingTop={"1rem"} sx={{ fontSize:{xs:"0.65rem" , sm:"1rem"}}}>Section 5</Box></div></div>
      </div>
        <FormControl id="Form1" >
          <Paper
            elevation={4}
            sx={{
              padding: { xs: "2rem", md: "4rem 8rem" },
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem", alignSelf:"center", marginBottom:"2rem" }}>
              Application Form
            </Typography>
            <Typography>
              Greetings, Innovators! 🚀
              <br />
              <br />
              This application is your canvas to paint the picture of your
              unique story. Dive into the questions, let your authenticity
              shine, and join us in shaping a community of bold thinkers. Ready
              to make your mark? Let's begin!
            </Typography>
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem" }}>
              Section 1
            </Typography>
            <Typography>Please fill all the required(*) fields.</Typography>
            <Divider></Divider>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>Full Name *</Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>Email *</Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Bharat@gmail.com"
                type="email"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography>I identify myself as a *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="others"
                  control={<Radio />}
                  label="Others"
                />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Where did you get to know about us? *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => {
                  setKnow(e.target.value);
                }}
              >
                <FormControlLabel
                  value="insta"
                  control={<Radio />}
                  label="Instagram"
                />
                <FormControlLabel
                  value="linkedin"
                  control={<Radio />}
                  label="LinkedIn"
                />
                <FormControlLabel
                  value="whatsapp"
                  control={<Radio />}
                  label="WhatsApp"
                />
                <FormControlLabel
                  value="friend"
                  control={<Radio />}
                  label="Friends"
                />
                <FormControlLabel
                  value="others"
                  control={<Radio />}
                  label="Others"
                />
              </RadioGroup>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Contact Number *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                onChange={(e) => {
                  setContact(e.target.value);
                }}
              />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  fullWidth
                  label="Date of Birth *"
                  onChange={(newValue) => {
                    setDob(newValue.toString());
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Current Location(City, State) *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Jaipur, Rajasthan"
                variant="outlined"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Which College are you from? *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="College...."
                variant="outlined"
                onChange={(e) => {
                  setCollege(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                In which year will you Graduate? *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="2024"
                variant="outlined"
                onChange={(e) => {
                  setYod(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What is your degree specialization? *
              </Typography>

              <Input
                fullWidth
                id="outlined-basic"
                label="B. Tech.."
                variant="outlined"
                onChange={(e) => {
                  setDegree(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Linkedin Profile Link *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="..."
                variant="outlined"
                onChange={(e) => {
                  setLinkedin(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography id="demo-radio-buttons-group-label">
                What Level are you at in the StartUp Journey? *
                <br />
                -1: You want to do a Startup but have no idea how to proceed,
                <br />
                0: You have an idea but you don’t know what the next step is,{" "}
                <br />
                1: You have ventured on the Startup Journey but need some
                guidance
                <br />
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => {
                  setLevel(e.target.value);
                }}
              >
                <FormControlLabel value="-1" control={<Radio />} label="-1" />
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="1" control={<Radio />} label="1" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography id="demo-radio-buttons-group-label">
                Do you have any eligible co-founder/s? *
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={"no"}
                onChange={(e) => {
                  setCofounderbool(e.target.value);
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                List eligible co founders.
                <br />
                Names, Emails & LinkedIn Profiles
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Your Answer"
                variant="outlined"
                disabled={cofounderbool === "no" ? true : false}
                onChange={(e) => {
                  setCofounder(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
              <Button
                variant="contained"
                onClick={handleNextClick}
                disabled={
                  name === "" ||
                  email === "" ||
                  gender === "" ||
                  contact === "" ||
                  dob === "" ||
                  college === "" ||
                  yod === "" ||
                  degree === "" ||
                  linkedin === "" ||
                  level === ""
                    ? true
                    : false
                }
              >
                Next
              </Button>
            </Box>
          </Paper>
        </FormControl>

        {/* Section 2
///
///
///
///
///
///
///
*/}

        <FormControl id="Form2">
          <Paper
            elevation={4}
            sx={{
              padding: { xs: "2rem", md: "4rem 8rem" },
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem" }}>
              Section 2
            </Typography>
            <Typography>
              Who are you?
              <br /> Unveil the essence of you. Share your personal journey,
              passions, pivotal moments, values, and aspirations. Engage in
              thought-provoking prompts, exploring your identity beyond the
              professional realm. This section, invites you to authentically
              express the unique facets that shape who you are.
            </Typography>
            <Typography>Please fill all the required(*) fields.</Typography>
            <Divider></Divider>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Outline your long-term vision for yourself. *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={(e) => {
                  setVision(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Highlight a challenging situation you've faced in your life and
                how you overcame it. *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Bharat@gmail.com"
                type="email"
                variant="outlined"
                onChange={(e) => {
                  setSituation(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                How do you feel about money? *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                onChange={(e) => {
                  setMoney(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Tell us about a project or achievement that you are most proud
                of. *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Jaipur, Rajasthan"
                variant="outlined"
                onChange={(e) => {
                  setProject(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What song best represents your big goals, and why does it
                inspire you? *
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="College...."
                variant="outlined"
                onChange={(e) => {
                  setSong(e.target.value);
                }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
              <Button variant="contained" onClick={handlePreviousClick}>
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleNextClick}
                disabled={
                  vison === "" ||
                  situation === "" ||
                  money === "" ||
                  project === "" ||
                  song === ""
                    ? true
                    : false
                }
              >
                Next
              </Button>
            </Box>
          </Paper>
        </FormControl>

        {/* Section 3
///
///
///
///
///
///
///
*/}

        <FormControl id="Form3">
          <Paper
            elevation={4}
            sx={{
              padding: { xs: "2rem", md: "4rem 8rem" },
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem" }}>
              Section 3
            </Typography>
            <Typography>
              Out of 5, I am.... <br />
              In this brief segment, we'll explore your self-awareness,
              emphasizing that it's not about excelling in every aspect, but
              rather understanding yourself. The Rating Scale measure is as
              follows:
              <br /> 1: Unsatisfactory
              <br /> 2: Needs Improvement <br />
              3: Satisfactory <br />
              4: Above Average
              <br /> 5: Outstanding
              <br />
            </Typography>
            <Typography>Please fill all the required(*) fields.</Typography>
            <Divider></Divider>
            <Box>
              <Typography>Rate your creativity. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue1(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your ability to stay calm. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue2(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your ability to seek feedback. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue3(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your commitment to development. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue4(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your leadership skills. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue5(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your problem-solving abilities. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue6(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>Rate your willingness to take risks. *</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue7(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>
            <Box>
              <Typography>
                Rate your capacity to bounce back from setbacks. *
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setQue8(e.target.value);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Box>

            <Box sx={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
              <Button variant="contained" onClick={handlePreviousClick}>
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleNextClick}
                disabled={
                  que1 === "" ||
                  que2 === "" ||
                  que3 === "" ||
                  que4 === "" ||
                  que5 === "" ||
                  que6 === "" ||
                  que7 === "" ||
                  que8 === ""
                    ? true
                    : false
                }
              >
                Next
              </Button>
            </Box>
          </Paper>
        </FormControl>
        {/* 
        Section 4
        //
        //
        //
        //
        //
        */}
        <FormControl id="Form4">
          <Paper
            elevation={4}
            sx={{
              padding: { xs: "2rem", md: "4rem 8rem" },
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem" }}>
              Section 4
            </Typography>
            <Typography>Please fill all the required(*) fields.</Typography>
            <Divider></Divider>
            <Typography>Do you have a startup idea? *</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={"yes"}
              onChange={(e) => {
                setIdea(e.target.value);
              }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            <Box sx={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
              <Button variant="contained" onClick={handlePreviousClick}>
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleNextClick}
                disabled={idea === "no" ? true : false}
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={idea === "yes" ? true : false}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </FormControl>
        {/* 
        Section 5
        
        
        */}
        <FormControl id="Form5">
          <Paper
            elevation={4}
            sx={{
              padding: { xs: "2rem", md: "4rem 8rem" },
              display: "flex",
              gap: "2rem",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: 600, fontSize: "2rem" }}>
              Section 5
            </Typography>
            <Typography>
              My Dream Start Up
              <br />
              This section is your platform to unveil the brilliance of your
              startup idea. Dive deep, articulate your motivation, and share the
              soul behind your vision. We're eager to discover the spark that
              sets your idea ablaze.
            </Typography>
            <Divider></Divider>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Summarize your startup idea in one sentence.
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={(e) => {
                  setStartupIdea(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What <b>problem</b> does your startup address?
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Bharat@gmail.com"
                type="email"
                variant="outlined"
                onChange={(e) => {
                  setProblem(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What is your proposed <b>solution</b> for the problem?
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                onChange={(e) => {
                  setSolution(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What is the inspiration behind your Startup Idea?
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="Jaipur, Rajasthan"
                variant="outlined"
                onChange={(e) => {
                  setInspiration(e.target.value);
                }}
              />
            </Box>

            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                Name your top 3 competitors in the market?
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="College...."
                variant="outlined"
                onChange={(e) => {
                  setCompetitors(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ marginBottom: "1rem" }}>
                What challenges are hindering the execution of your startup?
              </Typography>
              <Input
                fullWidth
                id="outlined-basic"
                label="2024"
                variant="outlined"
                onChange={(e) => {
                  setChallenges(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "2rem", margin: "1rem 0" }}>
              <Button variant="contained" onClick={handlePreviousClick}
              >
                Previous
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Paper>
        </FormControl>
      </Container>
    </Box>
    </div>
  );
}
