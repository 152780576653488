import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#03045E",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#03045E"
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h2:{
        fontFamily: 'Montserrat, sans-serif',
        fontSize: {xs: '1.8rem', md:'2rem'},
        fontWeight: '500'
    },
    h3:{
        fontFamily: 'Montserrat, sans-serif',
        fontSize: {xs: '1.8rem', md:'2rem'},
        fontWeight: '500',
        color: 'primary.contarstText'
    }
  },
  divider:{

  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  // </React.StrictMode>
);
