import React from 'react';
import './footer.css';
import { Box,Typography } from '@mui/material';
const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };
function Footer() {
    return (
		<>
		
        <div id="Footer">
        <footer className="footer">
		<Box sx={{ display: "flex", justifyContent:"center" }}>
          <Typography
            color={"white"}
            sx={{
              fontSize: "3rem",
              fontWeight: "800",
			  marginBottom:"2rem"
            }}
          >
            Get in Touch
          </Typography>
        </Box>
  	 <div className="container">
  	 	<div className="row">
  	 		<div className="footer-col">
  	 			<h4>side hustle</h4>
  	 			<ul>
				   <li><a onClick={()=>scrollTo("Home")}>Home</a></li>
					<li><a onClick={()=>scrollTo("About")}>About Us</a></li>
					<li><a onClick={()=>scrollTo("Journey")}>Journey</a></li>
  	 				{/* <li><a href="#">our services</a></li>
  	 				<li><a href="#">privacy policy</a></li>
  	 				<li><a href="#">affiliate program</a></li> */}
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>get help</h4>
  	 			<ul>
					<li><strong>Mail:</strong> <a href="mailto:admin@sidehustle.org.in" target="_blank" rel="noreferrer">admin@sidehustle.org.in</a></li>
				   {/* <li><a href='/form'  target="_blank" rel="noreferrer">Apply Now</a></li> */}
  	 				{/*<li><a href="#">returns</a></li>
  	 				<li><a href="#">order status</a></li>
  	 				<li><a href="#">payment options</a></li> */}
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>follow us</h4>
  	 			<div className="social-links">
  	 				<a href="https://www.facebook.com/profile.php?id=61555412983231&mibextid=kFxxJD" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
  	 				<a href="https://www.instagram.com/sidehustle.org.in?igsh=bmdjYnJvMHF5enZ3" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
  	 				<a href="https://www.linkedin.com/company/side-hustle-studio/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
  	 				<a href="https://www.youtube.com/channel/UCkDH9RSACeQx0D_0WfDQdbg" target="_blank" rel="noreferrer"><i class="fa-brands fa-youtube"></i></a>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
  </footer>
    </div>
	</>
  )
}

export default Footer;
