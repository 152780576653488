import Navbar from "./assets/Navbar/Navbar";
import Home from "./assets/Home/Home";
import About from "./assets/About/About";
import Calander from "./assets/Timeline/Timeline";
import Footer from "./assets/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Form from "./assets/Form";
import Counter from "./assets/Counter/Counter";
import Navbar2 from "./assets/Navbar2/Navbar";
function FinalApp() {
  return (
    <div id="Home">
      <Navbar/>
      <Home />
      <About />
      <Calander />
      <Counter/>
      <Footer />
      {/* <Form/> */}
    </div>
  );
}
function FinalForm() {
  return (
    <>
      <Navbar2/>
      <Form/>
    </>
  );
}
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<FinalApp/>}></Route>
          <Route path="/form" element={<FinalForm/>}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
