import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function About() {
  return (
    <Box
    id="About"
      sx={{
        width: "100%",
        // height: "90vh",
        bgcolor: "primary.main",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems:"center",
          padding: {xs:"3rem 0",md:"2rem 4rem"},
        }}
      >
        <Box sx={{ display: "flex", gap: "1.5rem" }}>
          <Typography
            color={"white"}
            sx={{
              zIndex: "1",
              fontSize: "3rem",
              fontWeight: "800",
              marginTop: {xs:"6rem", md:"12rem"},
            }}
          >
            About Us
          </Typography>
        </Box>
        <Box color="white" sx={{ margin: {xs:"2rem",md:"1rem 4rem"}, zIndex: "1" }}>
          <Typography color={"white"} align="justify" sx={{ fontSize: {xs:"1rem",md:"1.3rem"},paddingBottom:{xs:"1rem",md:"2rem"} , alignItems:"center"}}>
            A staggering 73% of college undergraduates aspire to carve their
            path as entrepreneurs and build the next big thing. At SIDE HUSTLE,
            we believe that these aspirations shouldn't remain dormant dreams
            but should be nurtured into thriving realities.
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/Nn1rzZyY-2w?si=UfeVJnsYhkgy7dss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            <br />
            <br />
            We are not just a startup; we are a startup for startups. Newly
            launched, our mission is to guide individuals on their
            entrepreneurial journey from -1 to 100. We firmly believe that every
            dream deserves a chance, and our goal is to turn startup
            aspirations into reality.<br/><br/>
            <b>HUSTLE100</b> marks the commencement of Side Hustle's journey, uniting 100 entrepreneurs from different corners of India. In just 100 days, these visionaries will transform their startup dreams into reality, starting from ground -1 to the first prototype launch.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
