import React, { useState,useEffect } from "react";
import "./counter.css";
import { Box, Typography } from "@mui/material";
import db from "../Firebase";
import { onValue, ref, set } from "firebase/database";

export default function Counter() {
  //////////////////////////////
  const [visitCounter, setVisitCounter] = useState("");
  
  // console.log(visitCounter)  
  useEffect(() => {
  function count() {
    return new Promise((resolve, reject) => {
      const counterRef = ref(db, "counter");
      onValue(
        counterRef,
        (snapshot) => {
          const counter = snapshot.val();
          resolve(Number(counter) + 1);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

    const updateCounter = async () => {
      try {
        const result = await count();
        setVisitCounter(result);
        await set(ref(db, "counter/"), Number(result));
      } catch (error) {
        console.error("Error fetching or updating counter:", error);
      }
    };


    function numberofusers() {
      return new Promise((resolve, reject) => {
        const counterRef = ref(db, "users");
        onValue(
          counterRef,
          (snapshot) => {
            const counter = snapshot.val();
            resolve(counter);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
    const users = async () => {
      try {
        const result = await numberofusers();
        var number_of_users = Object.keys(result).length;
        await set(ref(db, "numberOfUsers/"), Number(number_of_users));
      } catch (error) {
        console.error("Error fetching or updating counter:", error);
      }
    };



updateCounter();
users();

  }, []);
  useEffect(() => {
    function count() {
      return new Promise((resolve, reject) => {
        const counterRef = ref(db, "counter");
        onValue(
          counterRef,
          (snapshot) => {
            const counter = snapshot.val();
            resolve(Number(counter));
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  
      const updateCounter = async () => {
        try {
          const result = await count();
          setVisitCounter(result);
        } catch (error) {
          console.error("Error fetching or updating counter:", error);
        }
      };
  
      updateCounter();
      const intervalId = setInterval(updateCounter, 1000);
      return () => clearInterval(intervalId);
  
  
    }, []);

  return (
     <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "center",
          margin: { xs: "6rem 1.5rem 4rem", md: "10rem 1.5rem 3rem" },
        }}
      >
        <div className="counter">
          <div className="counter-animation">
            <div className="outer"></div>
            <div className="inner"></div>
          </div>
          <Typography sx={{ margin: "2px 8px" }}>
            <b>{visitCounter} Hustlers</b> checked in!
          </Typography>
        </div>
      </Box>
  );
}
