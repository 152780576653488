import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import "./timeline.css";

export default function Calander() {
  useEffect(() => {
    // Define variables
    const items = document.querySelectorAll(".timeline li");

    // Check if an element is in viewport
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    const callbackFunc = () => {
      for (let i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    };

    // Check if the document has already loaded
    if (document.readyState === "complete") {
      callbackFunc();
    }

    // Event listeners
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("load", callbackFunc);
      window.removeEventListener("resize", callbackFunc);
      window.removeEventListener("scroll", callbackFunc);
    };
  }, []);

  // (function () {
  //   // "use strict";
  //   var items = document.querySelectorAll(".timeline li");
  //   function isElementInViewport(el) {
  //     var rect = el.getBoundingClientRect();
  //     return (
  //       rect.top >= 0 &&
  //       rect.left >= 0 &&
  //       rect.bottom <=
  //         (window.innerHeight || document.documentElement.clientHeight) &&
  //       rect.right <=
  //         (window.innerWidth || document.documentElement.clientWidth)
  //     );
  //   }

  //   function callbackFunc() {
  //     for (var i = 0; i < items.length; i++) {
  //       if (isElementInViewport(items[i])) {
  //         items[i].classList.add("in-view");
  //       }
  //     }
  //   }
  //   window.addEventListener("load", callbackFunc);
  //   window.addEventListener("resize", callbackFunc);
  //   window.addEventListener("scroll", callbackFunc);
  // })();
  return (
    <Box
    id="Journey"
      sx={{
        width: "100%",
        bgcolor: "secondary.main",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Typography
          color={"#03045E"}
          sx={{ zIndex: "1", fontSize: {xs:"3rem",md:"4rem"}, fontWeight: "800",  }}
        >
         100 DAYS JOURNEY TRAIL
        </Typography>
        <Box sx={{position:"relative"}}>
          <section className="timeline">
            <ul>
              <li>
                <div>
                  <time>STARGAZE</time>
                  Domain Discovery Phase
                </div>
              </li>
              <li>
                <div>
                  <time>ICEBERG </time>
                  Problem Definition Phase
                </div>
              </li>
              <li>
                <div>
                  <time>ARMY</time>
                  Co Founder Matching Phase
                </div>
              </li>
              <li>
                <div>
                  <time>PANORAMA</time>
                  Market Research Phase
                </div>
              </li>
              <li>
                <div>
                  <time>CALCULATOR</time>
                  Solution Synthesis Phase
                </div>
              </li>
              <li>
                <div>
                  <time>DUMBLEDORE</time>
                  Mentor Matching Phase
                </div>
              </li>
              <li>
                <div>
                  <time>CAMERA </time>
                  Prototype Designing Phase
                </div>
              </li>
              <li>
                <div>
                  <time>PARACHUTE</time>
                  Launch Preparation Phase
                </div>
              </li>
              <li>
                <div>
                  <time>ZERO</time> Launch Phase 
                </div>
              </li>
              <li>
                <div>
                  <time>KING</time> 100 Customer Phase
                </div>
              </li>
              <li>
                <div>
                  <time>CHAMBER</time> Growth Phase
                </div>
              </li>
              <li>
                <div>
                  <time>ICECREAM</time> Convocation Phase
                </div>
              </li>
            </ul>
          </section>
        </Box>
      </Container>
    </Box>
  );
}
